.swal-payment2 .swal-footer div .swal-button--confirm {
  display: none;
}



.swal-payment2 .swal-title {
  font-size: 20px;
  color: #1a1a1a;
}

.swal-payment2 .swal-text {
margin-top: 12px;
}

.swal-payment2 .swal-title:not(:last-child) {
  margin-bottom: 0px;
}


.swal-payment2 .swal-content {
  margin-top: 10px;
}

.swal-payment2 .swal-footer {
  margin-top: 0px;
}

.swal__custom .swal-title {
  
  color: #1a1a1a;
  margin-bottom: 0px;

}