.swal-custom-editOrder .swal-title {
  font-size: 27px;

}

.swal-custom-editOrder .swal-button-container .swal-button--confirm {
  background-color: #dc3545 !important;
}

.swal-custom-editOrder-2 .swal-button-container .swal-button--confirm {
  background-color: #198754 !important;
}

.swal-custom-editOrder-2 .swal-button-container .swal-button--cancel{
  display: none !important;
}

.swal-custom-editOrder-add .swal-text {
  font-size: 24px;
  text-align: center;
}

.swal-custom-editOrder-add .swal-content {
  margin-top: 0 !important;
  font-size: 24px;
}

.swal-custom-editOrder-add .swal-content div p {
  font-weight: 400;
  color: rgba(0, 0, 0, .64);
  font-size: 24px;
}

.swal-custom-editOrder-add .swal-title {
  font-size: 16px;
}


.swal-custom-editOrder-add .swal-button-container .swal-button--confirm {
  background-color: #198754 !important;
}

.swal-text-check-user2 .swal-button-container .swal-button--confirm {
  background-color: #198754 !important;
}

.swal-text-check-user .swal-title {
  font-size: 16px;
}
.swal-text-check-user2 .swal-title {
  font-size: 20px;
}

.swal-text-check-user2 .swal-text {
  text-align: center;
}


 

.swal-text-check-user .swal-button-container .swal-button--confirm {
  background-color: #f07 !important;
}

.swal-text-check-user3 .swal-title {
  font-size: 18px;
}

.swal-text-check-user3 .swal-button-container .swal-button--confirm {
  background-color: #f07 !important;
}