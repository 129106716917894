.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.page_404_four_zero_four_bg {
  background-image: url(../../images/404.gif);
  height: 400px;
  background-position: center;
}

.page_404_four_zero_four_bg h1 {
  font-size: 80px;
}

.page_404_four_zero_four_bg h3 {
  font-size: 80px;
}

.page_404_link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #f07;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: all 0.2s;
  border-radius: 3px;
}

.page_404_link_404:active {
  transform: scale(0.95);
}

.page_404_contant_box_404 {
  margin-top: -50px;
}

.page_404_container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.page_404_row {
  margin-right: -15px;
  margin-left: -15px;
}

.page_404_col-sm-12,
.page_404_col-sm-10 {
  position: relative;
  min-height: 1px;
   
}

.page_404_h2 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  font-weight: 400;
  line-height: 1;
  color: #777;
  margin-top: 20px;
  margin-bottom: 10px;


}

.page_404_text-center {
  text-align: center;
}






@media (min-width: 768px) {
  .page_404_container {
    width: 750px;
  }

  .page_404_col-sm-12 {
    width: 100%;
    float: left;
  }

  .page_404_col-sm-10 {
    width: 83.33333333%;
    float: left;
  }

  .page_404_col-sm-offset-1 {
    margin-left: 8.33333333%
  }


}

@media (min-width: 992px) {
  .page_404_container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .page_404_container {
    width: 1170px;
  }
}