.swal__BittonGreen .swal-title{
  font-size: 20px;
  font-weight: 600;

}

.swal__BittonGreen .swal-footer .swal-button-container .swal-button--confirm{
 display: none;
}

.swal-text-check-user-cashTransferPreviousPayment .swal-title {
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  .swal-text-check-user-cashTransferPreviousPayment .swal-title {
    font-size: 20px;
  }
  .swal-text-check-user-cashTransferPreviousPayment .swal-text {
    font-size: 16px;
  }
}