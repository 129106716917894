 

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}



#address input {
  width: 100%;
}

div:has(input[name="province"]) {
  width: 100%;
}

.row>button {
  margin: auto;
  width: 15%;
  padding: 8px;
  font-size: 20px;
}

.form-input p {
  font-weight: bold;
  font-size: large;
  color: rgb(24, 102, 0);
}

.infoform h2 {
  text-align: center;
}


.modal-body h2 {
  text-align: center;
}

.form-feild {
  max-width: 1250px;
  margin-bottom: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid #3d3d3d;
  border-radius: 5px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}

.form-button {
  margin-left: auto;
  margin-right: auto;
  max-width: 1250px;
}

.image {
  width: 130px;
  height: 130px;
  border-radius: 8px;
  overflow: "hidden";
}

.container {
  margin: 25px 0px 75px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;

  text-align: center;
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: white;

  text-align: center;
}

.shopping-cart {
  margin-left: 80%;

}

.back-butt {
  margin-right: 80%;

}



.image-basket {
  width: 100%;
  height: auto;
  border-radius: 8px;
  overflow: "hidden";
}

.image_detail {
  width: 100%;
  height: auto;
  border-radius: 8px;
  overflow: "hidden";
}

.image-product {
  width: 100%;
  height: auto;
  border-radius: 8px;
  overflow: "hidden";
}

.datePicker {
  width: 70%;
}

.container-product {
  margin: 10px 0px 10px;
  position: relative;
}

.container-footer {
  bottom: -9%;
  position: absolute;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.image_role {
  width: 229px;
  height: auto;
  border-radius: 8px;
  overflow: "hidden";
}

.role-container {
  margin: 30px 0px 25px;
  width: 100%;
  text-align: center;
}



.cart-list-head {
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #eee;
}

.cart-list-title {
  background-color: #fff;
  padding: 18px 20px;
  border-bottom: 1px solid #eee;
}

@media (max-width: 767px) {
  .cart-list-title {
    text-align: center;
  }
}

 
 

 

.cart-single-list .remove-item {
  color: #fff;
  background-color: #f44336;
  font-size: 8px;
  height: 20px;
  width: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
}

@media (max-width: 767px) {
  .cart-single-list .remove-item {
    margin-top: 5px;
  }
}

.cart-single-list .remove-item:hover {
  color: #fff;
  background-color: #081828;
}

.cart-single-list .product-name {
  line-height: 25px;
}

.cart-single-list .product-name a {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  text-decoration: none;
}

.cart-single-list .product-des {
  display: block;
  margin-bottom: 0;
}

.cart-single-list .product-des span {
  font-size: 13px;
  font-weight: 500;
  display: block;
}

.cart-single-list .product-des span em {
  font-style: normal;
  color: #555;
}

.cart-single-list {
  padding: 30px 20px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.cart-single-list:last-child {
  border: none !important;
  border-radius: 0 0 4px 4px;
}

.cart-single-list a:hover {
  color: #0167F3;
}

.card {
  text-align: center;
  border-radius: 2%;
  margin: 10% 8px 8px;
  display: block;
}


.shopping-cart-b .total-amount .right ul li {
  font-size: 16px;
  font-weight: 500;
  color: #081828;
  margin-bottom: 12px;
}

.shopping-cart-b .total-amount .right ul li span {
  display: inline-block;
  float: right;
}

.shopping-cart-b .total-amount .coupon {
  padding: 40px;
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 40px;
  display: inline-block;
}

@media (max-width: 767px) {
  .shopping-cart-b .total-amount .coupon {
    width: 100%;
    padding: 30px;
    margin-top: 30px;
  }
}

.shopping-cart-b .total-amount .coupon input {
  width: 300px;
  height: 46px;
  border: 1px solid #dddddd;
  overflow: hidden;
  border-radius: 4px;
  color: #333;
  padding: 0px 20px;
  display: inline-block;
}

@media (max-width: 767px) {
  .shopping-cart-b .total-amount .coupon input {
    width: 100%;
  }
}

.shopping-cart-b .total-amount .coupon .button {
  display: inline-block;
}

@media (max-width: 767px) {
  .shopping-cart-b .total-amount .coupon .button {
    display: block;
  }
}

.shopping-cart-b.total-amount .coupon button {
  border: none;
  height: 46px;
  display: inline-block;
  margin-left: 8px;
  position: relative;
  top: -2px;
}

@media (max-width: 767px) {
  .shopping-cart-b .total-amount .coupon button {
    margin: 0;
    margin-top: 10px;
    width: 100%;
  }
}

.shopping-cart-b .total-amount .right {
  padding: 40px;
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 40px;

}

@media (max-width: 767px) {
  .shopping-cart-b .total-amount .right {
    padding: 15px;
    margin-top: 30px;
  }
}

.shopping-cart-b .total-amount .right .button {
  margin-top: 30px;
}

.shopping-cart-b .total-amount .right .btn {
  width: 100%;
  margin-bottom: 8px;
  text-align: center;
  padding: 12px 20px;
  width: 100%;
  background-color: #f07;
  color: #fff;
  transition: 0.2s;
}

.shopping-cart-b .total-amount .right .btn:active {
  transform: scale(0.95);
  background-color: #f44336;
}

.shopping-cart-b .total-amount .right .btn:hover {
  background-color: #f50057;
}

.shopping-cart-b .total-amount .right .btn:last-child {
  margin: 0;
}

@media (max-width: 767px) {
  .shopping-cart-b .count-input {
    padding: 10px 65px;
  }
}

@media (max-width: 767px) {
  .shopping-cart-b p {
    padding: 3px 0;
  }
}

.shopping-cart-b select.form-control:not([size]):not([multiple]) {
  height: 46px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  width: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shopping-cart-b select.form-control:not([size]):not([multiple]) {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .shopping-cart-b select.form-control:not([size]):not([multiple]) {
    width: 100%;
  }
}

.shopping-cart-b select.form-control:not([size]):not([multiple]):focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.shopping-cart-b select.form-control {
  padding-right: 38px;
  background-position: center right 17px;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTguMS4xLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDE4NS4zNDQgMTg1LjM0NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTg1LjM0NCAxODUuMzQ0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCI+CjxnPgoJPGc+CgkJPHBhdGggZD0iTTkyLjY3MiwxNDQuMzczYy0yLjc1MiwwLTUuNDkzLTEuMDQ0LTcuNTkzLTMuMTM4TDMuMTQ1LDU5LjMwMWMtNC4xOTQtNC4xOTktNC4xOTQtMTAuOTkyLDAtMTUuMTggICAgYzQuMTk0LTQuMTk5LDEwLjk4Ny00LjE5OSwxNS4xOCwwbDc0LjM0Nyw3NC4zNDFsNzQuMzQ3LTc0LjM0MWM0LjE5NC00LjE5OSwxMC45ODctNC4xOTksMTUuMTgsMCAgICBjNC4xOTQsNC4xOTQsNC4xOTQsMTAuOTgxLDAsMTUuMThsLTgxLjkzOSw4MS45MzRDOTguMTY2LDE0My4zMjksOTUuNDE5LDE0NC4zNzMsOTIuNjcyLDE0NC4zNzN6IiBmaWxsPSIjNTA1MDUwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
  background-repeat: no-repeat;
  background-size: 10px 10px;
}

.shopping-cart-b .form-control {
  padding: 0 18px;
  -webkit-transition: color .25s, background-color .25s, border-color .25s;
  transition: color .25s, background-color .25s, border-color .25s;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #fff;
  color: #505050;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.shopping-cart-b .form-control:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.num-block {
  float: left;
  width: 100%;

}

.skin-2 .num-in {
border: 1px solid #e0e0e0;
  border-radius: 10px;
  height: 40px;

  float: left;
}

.skin-2 .num-in span {
  width: 30%;
  display: block;
  height: 40px;
  float: left;
  position: relative;
  cursor: pointer;
}

.skin-2 .num-in span {
  cursor: pointer;
  transition: 0.2s;

}



.skin-2 .num-in span:before,
.skin-2 .num-in span:after {
  content: '';
  position: absolute;
  background-color: #667780;
  height: 2px;
  width: 10px;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: -5px;
  transition: all 0.2s;
}

.skin-2 .num-in span:hover:before,
.skin-2 .num-in span:hover:after {
  background-color: #081828;

}

.skin-2 .num-in span:active:before,
.skin-2 .num-in span:active:after {
  transform: scale(0.95);

}

.skin-2 .num-in span.plus:after {
  transform: rotate(90deg);
}

.skin-2 .num-in input {
  float: left;
  width: 40%;
  height: 35px;
  border: none;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .num-block {

    margin-top: 10px;
    margin-bottom: 10px;
  }

  .DelectBaskets {
    margin-top: 10px;

  }
}



.DelectBaskets {
  color: #888;
  cursor: pointer;
  transition: all 0.2s;
}

.DelectBaskets:active {
  transform: scale(0.95);
}

.DelectBaskets:hover {
  color: red;
}

.bring {
  padding: 8px;
  border-radius: 4px;
  width: 380px;
  box-shadow: 0px 0px 20px #00000012;
  margin: 5px
}

.bring div img {
  width: 100px;
  height: auto;

}


#content-active {
  width: 100%;
 
 
  position: fixed;
  /* box-shadow: 0px 0px 20px #00000012; */
  bottom: 0;
  left: 0;
  /* color: #fff; */
  justify-content: center;
  align-items: center;
  z-index: 1;
  display: flex;
  /* border: 1px solid #dfdfdf;
  cursor: pointer; */
 
  transition: 0.2s;
}


#content-active #ok{
  width: 100%;
 padding: 20px;
 border:1px solid #198754;
 background-color: #198754;
  transition: all 0.2s;
  box-shadow: 0px 0px 20px #00000012;
  color: #fff;
  font-size: 18px;
}

#content-active #back{
  width: 100%;
  padding: 20px;
  border: 1px solid #dc3545;
  background-color: #dc3545;
    transition: all 0.2s;
  box-shadow: 0px 0px 20px #00000012;
  color: #fff;
  font-size: 18px;
}

/* #content-active #ok:hover {
background-color: #f5f5f5;
  color: #33b113;
}
#content-active #back:hover{
background-color: #f5f5f5;
  color: #1e0eff;
} */

#content-active #ok:active {
  transform: scale(0.99);
}

#content-active #back:active {
  transform: scale(0.99);
}

#nav-bring {
  width: 100%;
  height: 60vh;
 
  position: fixed;
  /* box-shadow: 0px 0px 20px #00000012; */
 z-index: 999 ;
  align-items: center;
  /* left: 0; */
  display: flex;
  justify-content: center;
  flex-direction: column;

}

#nav-bring .bg-white {
  padding: 23px 40px 23px 40px;
  box-shadow: 0px 0px 20px #00000012;
  border-radius: 10px;
  background-color: rgb(255, 228, 225) !important;
  margin: 10px;
}

#nav-bring .bg-white .btn {
  background-color: transparent;
  border: 1px solid #dfdfdf;
  color: #081828;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
transition: all 0.2s;
}

#nav-bring .bg-white .btn:hover{
  background-color: #f5f5f5;
  color: #081828;
}
#nav-bring .bg-white .btn:active{
transform: scale(0.95);
}

#nav-bring p {
  width: 100%;
}

#nav-bring #deliveryDate {
  display: flex;

  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

#nav-bring #deliveryDate h1 {
  font-size: 20px !important;
  text-align: center;
  margin-bottom: 30px;
  font-weight: unset;
}


#nav-bring #deliveryDate .react-datepicker-wrapper .react-datepicker__input-container div input {
  text-align: center;
  border: 1px solid #dfdfdf;
}

#nav-bring .vr {
  display: flex;
  align-items: center;

  justify-content: center;
  height: 35px;
  margin-top: 10px;

  margin-right: 10px;
  margin-left: 10px;
}

#nav-bring #sender-name {
  display: flex;
  align-items: center;

}

.react-datepicker-wrapper {
  text-align: center !important;
}

.react-datepicker__close-icon {
  display: none !important;
}

@media screen and (max-width: 831px) {
  .bring {
    width: 325px;
  }
}

@media screen and (max-width: 721px) {
  .bring {
    width: 100%;
  }


}



.skin-7 .num-in {
  float: left;
  width: 138px;

}

.skin-7 input.in-num {
  font-family: 'HelveticaNeueCyr-Roman';
  font-size: 14px;
  float: left;
  height: 32px;
  width: 83px;
  border-left: 1px solid #a4a4a3;
  border-right: 1px solid #a4a4a3;
  background-color: #fff;
  text-align: center;
}

.skin-7 .num-in span {
  font-size: 20px;
  text-align: center;
  border-radius: 50%;
  width: 35px;
  float: left;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f6;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.skin-7 .num-in span:hover {
  background-color: #d7d7d8;
}

.skin-7 .num-in input {
  border: none;
  float: left;
  width: 44px;
  line-height: 34px;
  text-align: center;
  font-family: 'helveticaneuecyrbold';
}

.form-select:after {
  content: '';
  position: absolute;
  background-color: #f4f4f6;
  height: 2px;
  width: 10px;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: -5px;
  transition: all 0.2s;
}

#standard-select {
  color: rgb(8, 24, 40);
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 6px;
  margin-top: 4px;
  width: 90%;
font-size: 20px;
height: 50px;


}

#standard-select2 {
  color: rgb(8, 24, 40);
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 6px;
  margin-top: 4px;
  width: 90%;
  font-size: 20px;
  height: 50px;

}

#standard-select2:disabled {
  background-color: #f4f4f6;
}

@media screen and (max-width: 343px) {
  #standard-select2 {
    margin-left: 0px;
  }

  #standard-select {
    margin-right: 0px;

  }
 
}

 


#setNavBarShipper{
  color:#ffff;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  /* position: fixed;
  top: 0;
  left: 0; */
  box-shadow: 0px 0px 20px #00000012;
  width: 100%;
  height: 5rem;
  background-color: #660099;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
}

#setNavBarShipper p {
  font-size: 15px !important;
}

#Discounts{
  -webkit-appearance: none;
  background-color: #fff;
  border: none;
  font-size: 14px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid rgba(0,0,0,.14);
  padding: 10px 13px;
  border-radius: 2px;
  transition: border-color .2s;
}

#invoice_code {
      color: rgb(8, 24, 40);
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    padding: 6px;
    margin-top: 4px;
    font-size: 20px;
}
 
.text-upload-btn{
  position: absolute;
  right: 0;
  top: 30%;
  left: 4%;
  width: 92px;
  text-align: center;
  background: #00000069;
  color: #fff;
  padding: 5px;
  cursor: pointer;
  font-size: 11px;
}


#Creatable{
    color: rgb(8, 24, 40);
   
      border-radius: 4px;
     
      margin-top: 4px;
      width: 170px;
}


 .borderless{
    display: flex;
    align-items: center;
 }


 .sweet-alert-CancelOrder .swal-text{
  font-size: 15px;
 }



.form-select:focus {
  border-color: #00a8ff;
  box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.1);
  
}


.input-customerID div input {
  background-color: #FFFFFF;
}




[aria-describedby="alert-dialog-description"] {
   margin: 0 !important;
}

[aria-describedby="alert-dialog-description2"] {
  margin: 0 !important;
}

.MentorMonthlySales-StyledTableCell{
  width: 100%;
}


/* ถ้าตํ่ากว่า 560px จะเป็นการแสดงเป็นแนวตั้ง */
@media screen and (max-width: 560px) {
  .MentorMonthlySales-StyledTableCell {
    width: 200px;
  }
}