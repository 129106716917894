.swal-custom-checkout .swal-footer .swal-button-container .swal-button--confirm{
    background-color: #00a65a !important;
    color: #fff !important;
 }
 
 .pr-0{
        padding-right: 0px !important;
 }

    .pl-0{
        padding-left: 0px !important;   
    }


    .overflow-example {
        display: -webkit-box !important;
        -webkit-box-orient: vertical;
    
        -webkit-line-clamp: 1; /* เอาไว้กำหนดจำนวนบรรทัด */
        overflow: hidden;
        text-overflow: ellipsis;
      }


      .text-right{
        text-align: right !important;
      }

      .text-left{
        text-align: left !important;
      }

      .text-center{
        text-align: center !important;
      }


            .disabled {
              opacity: 0.65;
              cursor: not-allowed !important;
            }