#conten_ul{
    min-height: 100vh;
    overflow-y: auto;
 
  
}

#conten_ul::-webkit-scrollbar {
      display: none;
}

 
 
.li-box_{
  
    padding:1rem;
    margin:2px;
   
    cursor: pointer;
   
}
 
#sidebar {
 
  background: #660099;
  color: #000;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: fixed;
 z-index: 99;
    overflow-y: auto;
      height: 100%;
}

 


#sidebar .h6 {
  color: #fff;
}
#sidebar.active {
  margin-left: 0px;
}
#sidebar h1 {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 30px;
}

#sidebar ul.components {
  padding: 0;
}
#sidebar ul li {
  font-size: 16px;
}

 

#sidebar ul li > ul {
  margin-left: 10px;
}
#sidebar ul li > ul li {
  font-size: 14px;
}
#sidebar ul li a {
  padding: 10px 0;
  display: block;
  color: rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
#sidebar ul li a span {
  color: #44bef1;
}
#sidebar ul li a:hover {
  color: #fff;
}
#sidebar ul li.active > a {
  background: transparent;
  color: #fff;
}
 
#sidebar .custom-menu {
  display: inline-block;
  position: absolute;
  top: 3px;
  right: 0;
  
  margin-right: -20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  #sidebar .custom-menu {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
#sidebar .custom-menu .btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
}
#sidebar .custom-menu .btn i {
  margin-right: -46px;
  font-size: 14px;
}
#sidebar .custom-menu .btn.btn-primary {
  background: transparent;
  border-color: transparent;
}
#sidebar .custom-menu .btn.btn-primary:after {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #660099 ;
  border-radius: 10px;
}
#sidebar .custom-menu .btn.btn-primary:hover,
#sidebar .custom-menu .btn.btn-primary:focus {
  background: transparent !important;
  border-color: transparent !important;
}

.wrapper {
  width: 100%;
  background: #ffddf4;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 991.98px) {
  #sidebarCollapse span {
    display: none;
  }
}

#content__ {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-left: 58px;
}

 

.btn.btn-primary {
  background: #081828;
  border-color: #081828;
}
.btn.btn-primary:hover,
.btn.btn-primary:focus {
  background: #081828 !important;
  border-color: #081828 !important;
}

 

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.sweet-alert-delete-order .swal-button-container .swal-button--confirm{
  background-color: #dc3545 !important;
}

.sweet-alert-delete-order-success .swal-button-container .swal-button--confirm{
  background-color: #00a65a !important;
}
.sweet-alert-delete-order-success-2 .swal-button-container .swal-button--confirm {
  background-color: #00a65a !important;
}

.sweet-alert-delete-order-success-2 .swal-button-container .swal-button--cancel{
 display: none !important;
}
.sweet-alert-info-custom .swal-button-container .swal-button--confirm{
  background-color: #00a65a !important;
}

.dialog-container-orders [aria-hidden="true"]{
  background-color: #ffddf4 !important;
} 


.dialog-container-orders [role="presentation"] div{
  border-radius: 10px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}


.pink{
  background-color: #eb2f96 !important;
}

.green{
  background-color: #52c41a !important;
}
 
.greener{
  background-color: #228b22 !important;
}

.orange{
  background-color: #fa8c16 !important;
}

.blue{
  background-color: #1976d2 !important;
}
.white{
  background-color: #fff;
}

.light-orange{
  background-color: #FEE8D6;
}

.color-dark{
  color:rgba(0, 0, 0, 0.87) !important;
}

.sweet-alert-delete-order .swal-text{
  font-size: 16px !important; 
  text-align: center !important;
}

.sweet-alert-delete-order .swal-title{
  font-size: 22px !important;
}
.sweet-alert-info-custom .swal-title{
  font-size: 22px !important;
}

.sweet-alert-info-custom .swal-text{
  text-align: center !important;
}

.react-datepicker__day--selected{
  background-color: #1976d2 !important;
  color: #fff !important;
}

/* ชมพู */
.bg-pink {
  background-color: #ff3399;
  color: #fff !important;
}