.text-heading-AruneeNoodles-1{
    color: white; 
    text-shadow: black 0.1em 0.1em 0.2em;
    /* เอียง */
    font-style: italic;
}
 

 
.Table-costAruneeNoodles-border>:not(caption)>* {
    border: 1px solid #11103f;
    border-width: 5px 0;
    
}
.Table-costAruneeNoodles-border>:not(caption)>*>*{
    border: 1px solid #11103f;
    border-width: 0 5px;
}

.Table-costAruneeNoodles-border > :not(caption) {
    border-radius: 10px;
    overflow: hidden; /* ตัดมุมให้เหมือนกัน */
}

.border-style-AruneeNoodles-1-none{
    border-style: none solid solid none;
}

.background-orange-costAruneeNoodles-1{
    background-color: #ff9900 !important;
}

.background-orange-costAruneeNoodles-2{
    background-color: #ffd3b1 !important;
}

 
.background-blue-costAruneeNoodles-1{
    background-color: #4092f3 !important;
}

.background-blue-costAruneeNoodles-2{
    background-color: #b3d1ff !important;
}

.background-blue-costAruneeNoodles-3{
    background-color: #73aaf2 !important;
}

.background-green-costAruneeNoodles-1{
    background-color: #88e863 !important;
}

.background-green-costAruneeNoodles-2{
    background-color: #aeee96 !important;
}

.background-green-costAruneeNoodles-3{
    background-color: #d3f5c4 !important;
}