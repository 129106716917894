.clickTh{
    cursor: pointer;
    transition: all 0.3s;
}

.clickTh:hover{
    background-color: #5e0101 !important;
}

.pl-05{
    padding-left: .5rem !important;
}

.pr-05{
    padding-right: .5rem !important;
}

.pr-06{
    padding-right: .6rem !important;
}

.pl-06{
    padding-left: .6rem !important;
}

 
.text-line{
    text-decoration: line-through;
}

.pointer{
    cursor: pointer;
}
 