@import url('https://fonts.googleapis.com/css2?family=Dosis&display=swap');
#file-TextField-label{
    color: rgba(0, 0, 0, 0.6) !important;
 
}

#file-TextField{
    -webkit-text-fill-color : #0a58ca !important;
}

.p1rem{
    padding: 1rem 1rem !important;
}

.badge-primary{
    background-color: #0a58ca !important;
}
.fs09{
    font-size: 0.9rem !important;
}

.badge-owner{
    background-color: #0a58ca !important;
    color: #fff !important;
}

.badge-orderer{
    background-color: #ca900a !important;
    color: #fff !important;
}

.badge-finance{
    background-color: #0d8d01 !important;
    color: #fff !important;
}

.badge-receiving{
    background-color: #ca0a0a !important;
    color: #fff !important;
}

.badge-disabled{
    background-color: #6c757d !important;
    color: #fff !important;
}

.badge-FreeStudent{
    background-color: #bc04c0 !important;
    color: #fff !important;

}

.swal-jobType .swal-title{
    font-size: 1.25rem !important;
    margin-top: 0;
    margin-bottom: 0;
    color: #000;
    font-weight: 500;
    text-align: left;
    padding: 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.211);
}

.swal-jobType .swal-content{
    margin: 0;
    padding: 0rem;
}

.swal-jobType .swal-footer{
    margin: 0;
    padding: 0.6rem;
    border-top: 1px solid rgba(0, 0, 0, 0.211);
}

.text-left{
    text-align: left !important;
}

.swal-jobType .swal-footer .swal-button-container .swal-button--catch{
    background-color: #0d6efd !important;
    color: #fff !important;
}

.swal-jobType .swal-footer .swal-button-container .swal-button--catch:hover{
    background-color: #0b64e9 !important;
    color: #fff !important;
}


.active-jobType{
    background: var(--bs-gray-900) !important;
     color: #fff !important;
}

.active-jobType:hover{
    background: var(--bs-gray-900) !important;
     color: #fff !important;
}
 
.empty-icon-container {
    margin: 1em auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.animation-container {
    position: relative;
    display: block;
    text-align: center;
    height: 100px;
    width: 100px;
    border-bottom: solid 2px #333;
    overflow: hidden;
}

.bounce {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 40px;
    height: 40px;
    margin-left: -20px;
    background-size: contain;
    animation: bounceAnim 1s cubic-bezier(.63, .09, .75, .46) infinite alternate,
        spinAnim 3s linear infinite;
}

@keyframes bounceAnim {

    0%,
    10% {
        bottom: 50%;
    }

    100% {
        bottom: 0%;
    }
}

@keyframes spinAnim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.pebble1 {
    position: absolute;
    top: 95%;
    background-color: #333;
    width: 10px;
    height: 10px;
    border-radius: 20px 20px 0px 0px;
    animation: pebblesAnim 1s linear infinite;
}

.pebble2 {
    position: absolute;
    top: 97%;
    background-color: #333;
    width: 5px;
    height: 5px;
    border-radius: 10px 10px 0px 0px;
    animation: pebblesAnim 2s linear infinite;
}

.pebble3 {
    position: absolute;
    top: 98%;
    background-color: #333;
    width: 3px;
    height: 3px;
    border-radius: 20px 20px 0px 0px;
    animation: pebblesAnim 3s linear infinite;
}

@keyframes pebblesAnim {
    0% {
        right: -20%;
    }

    100% {
        right: 110%;
    }
}

.h2NoData{
        display: block;
            font-size: 1.5em;
            margin-block-start: 0.83em;
            margin-block-end: 0.83em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: bold;
    font-family: 'Dosis', sans-serif !important;
}

.fs-13{
    font-size: 1.3rem;
}

.fs-15{
    font-size: 1.5rem;
}

.fs-08{
    font-size: 0.8rem;
}