@import url("./icon/style.css");
@import url("bootstrap/dist/css/bootstrap.min.css");
@import url("react-datepicker/dist/react-datepicker.css");
@import url("./main.css");
@import url("https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

body {
  margin: 0;
  font-family: "Sarabun", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   
  
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
a,
p,
span,
div,
input,
label,
textarea,
select,
option,
optgroup,
li,
ul,
ol,
dl,
dt,
dd,
table,
thead,
tbody,
tfoot,
tr,
th,
td,
form {
  font-family: "Sarabun", sans-serif !important;
  
}


.bg-f07{
  background-color: #f07 !important;
}

.color-fff{
  color: #fff !important;
}

.f-20{
  font-size: 20px !important;
}

.f-18{
  font-size: 18px !important;
}

 